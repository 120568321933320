@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

a {
  color: white !important; /* blue colors for links too */
  text-decoration: none !important; /* no underline */
}

.App {
  text-align: center;
  font-family: "Open Sans";
}
 
.header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
  position: relative;

  &__info {
    z-index: 2;
  }
  
  &__title{
    margin: 0;
    font-weight: unset;
  }
  
  
  &--colored {
    color: orangered;
  }
  
  &__button{
    margin-top: 1rem;
    height: 3rem;
    width: 20rem;
    padding-left: 0.5rem;
    padding-right: 2.25rem;
    border: 2px solid white;
    background-color: inherit;
    color: white;
    font-size: 1.5rem;
    position: relative;
    transition: 1s;
    
    &:hover{
      background-color: #3FC0E3;
      border-color: #3FC0E3;
      
      & .header__arrow {
        animation: reverse-spin 1s both;
      }
    }
  }

  &__arrow{
    position: absolute;
    height: 2rem;
    right: 0.75rem;
    top: 0.5rem;
    
    &--animation {
      animation: forward-spin 1s backwards;
    }
  }
}

.about {
  background-color: #F0F8FF;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  &__title {
    margin-top: 2rem;
    margin-bottom: 5rem;
    font-size: 3rem;
    border-bottom: 3px solid #282828;
    color: #282828;
  }

  &__me {
    display: flex;
    flex-direction: row;
  }

  &__bio {
    display: flex;
    flex-direction: column;
    align-items: center;
  }



  &__image {
    width: 17.5rem;
    height: 17.5rem;
    border-radius: 1rem;
  }

  &__textHeader {
    font-size: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding-bottom: 0;
    color: #282828;
  }

  &__text {
    margin: 0 0 0.25rem;
    font-size: 1rem;
    width: 25rem;
    color: #585858;
  }

  &__link {
    color: blue;
    text-decoration: none;
  }

  &__contact {
    color: blue;
    text-decoration: underline;
    cursor: pointer;

  }
}

.skills {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30rem;

  &__big {
    width: 30rem;
    height: 12rem;
  }

  &__small {
    width: 30rem;
    height: 12rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__node {
    width: 9rem;
    height: 9rem;
  }

  &__react {
    width: 6.5rem;
    height: 7.5rem;
  }

  &__sql {
    width: 10.6rem;
    height: 8rem;
  }


}

.projects {
  background-color: #FFF0F5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 5rem;
    margin-top: 2rem;
    font-size: 3rem;
    border-bottom: 3px solid #282828;
    color: #282828;
  }

  &__projectList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__button{
    border: none;

    &:hover, &:active, &:focus {
    outline: none; 
    }
  }

  &__card {
    height: 18.75rem;
    width: 24rem;
    margin-bottom: 3rem;
  }

  &__kanban {
    height: 18.75rem;
    width: 24rem;
  }

  &__dehanee {
    height: 12rem;
    width: 24rem;
  }

  &__microsoft {
    height: 14rem;
  }

  &__earthDefence {
    height: 14rem;
  }
}

.contact {
  background-color: #282c34;
  min-height: 30rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    margin-bottom: 1rem;
    font-size: 3rem;
    margin-top: 3rem;
    border-bottom: 3px solid white;
    color: white;
  }

  &__question {
    color: lightblue;
  }

  &__clickables {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 60%;
    margin-top: 2rem;

  }

  &__logo {
    height: 12rem;
    width: 12rem;
  }


}

@keyframes reverse-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-270deg);
  }
}

@keyframes forward-spin {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
