.overlayWrapper {
    height:100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

.overlay {
    animation: spin 60s linear infinite;
    height:100%;
    width: 100%;
    position: absolute;
       
    &__dot {
        position: absolute;
        height: 2px;
        width: 2px;
    }
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(-360deg);
    }
  }